<template>
    <b-row class="match-height">
      <b-col
  lg="10"
  offset="1"
  > <b-card
      title="Confirm Transfer By Warehouse Sender"
    >
    <b-form
          @submit.prevent="submitConfirmTransfer"
  >
        <b-row>
          <b-col cols="12">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
              <b-dropdown
                text="Download Documents"
                v-if="documents.length > 0"
                class="float-rigth"
              >
                <!-- Your dropdown content here -->
                <b-dropdown-item
                  v-for="document in documents"
                  :key="document.document_transfer_id"
                  @click="downloadAttachment(document)"
                  >{{
                    document.document_file_name
                  }}</b-dropdown-item
                >
              </b-dropdown>
            <div class="devider" />
            </b-col>
        <b-col cols="12">
          <b-form-group
            label="Transfer Number"
            label-for="transfer_number"
            label-cols-md="12"
          >
            <b-form-input
              id="transfer_number"
              v-model="transferNumber"
              placeholder="Transfer Number"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Transfer Sender *"
            label-for="transfer_sender"
            label-cols-md="12"
          >
            <b-form-input
              id="transfer_sender"
              v-model="transferSender"
              placeholder="Transfer Sender"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Transfer Receiver *"
            label-for="transfer_receiver"
            label-cols-md="12"
          >
            <b-form-input
              id="transfer_receiver"
              v-model="transferReceiver"
              placeholder="Transfer Receiver"
              readonly
            />
          </b-form-group>
        <b-form-group
          label="Transfer Type *"
          label-for="transfer_type"
          label-cols-md="12"
        >
          <b-form-input
            id="transfer_type"
            v-model="transferType"
            placeholder="Transfer Type "
            readonly
          />
        </b-form-group>
          <b-form-group
            label="Transfer Notes *"
            label-for="transfer_notes"
            label-cols-md="12"
          >
            <b-form-input
              id="transfer_notes"
              v-model="transferNotes"
              placeholder="Transfer Notes "
              readonly
            />
          </b-form-group>
        <b-form-group>
          <label for="no_invoice">Status :</label>
          <vue-horizontal-timeline :items="statusItems" />
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Items :</label>
        </b-form-group>
        <b-form-group>
          <br>
           <div  v-if="transferType!='Asset'">
          <b-row>
            <b-col
  cols="3"
  class="text-center font-weight-bold"
  >
              {{transferType=='Asset' ? 'Asset Name' : 'Item Name'}}
            </b-col>
            <b-col
  cols="3"
  class="text-center font-weight-bold"
  >
              Requested QTY
            </b-col>
            <b-col
  cols="3"
  class="text-center font-weight-bold"
  >
              Sent QTY
            </b-col>
            <b-col
  cols="3"
  class="text-center font-weight-bold"
  >
              Shelf
            </b-col>
            </b-row>
           <p />
          <b-row
  v-for="(item) in items"
  :key="item.transfer_detail_item_id"
  >
          <br>
            <b-col
  cols="3"
  class="text-center"
  >
                <b-list-group>
                  <b-list-group-item href="#">
                  <span v-if="item.asset">
  {{ item.asset.asset_number }} ({{ item.asset.item.item_code }})
</span>
<span v-else-if="item.item">
  {{ item.item.item_name }} ({{ item.item.item_code }})
</span>
  </b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col
  cols="3"
  class="text-center"
  >
            <b-form-input
              v-model="item.qty"
              class="text-center"
              readonly
  />
            </b-col>
            <b-col
  cols="3"
  class="text-center"
  >
            <b-form-input
              v-model="item.accepted"
              :max="item.qty"
              type="number"
              class="text-center"
  />
            </b-col>
              <b-col cols="3" class="text-center">
                <v-select-new
                      v-model="item.shelf"
                      :options="item.shelves"
                    />
              </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
            </div>

           <div  v-if="transferType=='Asset'">
          <b-row>
            <b-col
  cols="6"
  class="text-center font-weight-bold"
  >
              {{transferType=='Asset' ? 'Asset Name' : 'Item Name'}}
            </b-col>
            <b-col
  cols="3"
  class="text-center font-weight-bold"
  >
              Requested QTY
            </b-col>
            <b-col
  cols="3"
  class="text-center font-weight-bold"
  >
              Sent QTY
            </b-col>
            </b-row>
           <p />
          <b-row
  v-for="(item) in items"
  :key="item.transfer_detail_item_id"
  >
          <br>
            <b-col
  cols="6"
  class="text-center"
  >
                <b-list-group>
                  <b-list-group-item href="#">
                  <span>
  {{ item.asset.asset_number }} ({{ item.asset.item.item_code }})
</span>
  </b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col
  cols="3"
  class="text-center"
  >
            <b-form-input
              v-model="item.qty"
              class="text-center"
              readonly
  />
            </b-col>
            <b-col
  cols="3"
  class="text-center"
  >
            <b-form-input
              v-model="item.accepted"
              :max="item.qty"
              type="number"
              class="text-center"
  />
            </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
            </div>
        </b-form-group>
          </b-col>

          <b-col offset-md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="isSubmitDisabled"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
        </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"
  import { ref } from '@vue/composition-api'

  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const documents = []
  const statusItems = []
  const shelves = []
  const supplier = ''
  const shipmenBy = ''
  const deliveryAddress = ''
  const transferSchedule = ''
  const transferNotes = ''
  const transferReceiver = ''
  const transferSender = ''
  const billingAddress = ''
  const termOfPayment = ''
  const paymentType = ''
  const transferType = ''
  const transferNumber = ''
  const transferIsConfirmed = false
  const transferIsReceived = false
  const isSubmitDisabled = false
  export default {
    components: {
      VueHorizontalTimeline,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
      BListGroup,
      BListGroupItem,
      BDropdown,
      BDropdownItem,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        transferType,
        transferSender,
        transferReceiver,
        transferNotes,
  shelves,
        isSubmitDisabled,
        documents,
        transferNumber,
      transferIsConfirmed,
      transferIsReceived,
      paymentType,
      statusItems,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      supplier,
      shipmenBy,
      deliveryAddress,
      transferSchedule,
      billingAddress,
      termOfPayment,
      }
    },
    mounted() {
      const shelfsVariables = process.env.VUE_APP_SHELF_LIST.split(",")
      const shelfsRepeat = process.env.VUE_APP_SHELF_REPEAT.split(",")
      this.shelves = []
      shelfsVariables.map(s => {
        shelfsRepeat.map(r => {
          this.shelves.push(`${s}${r}`)
        })
      })
        this.getDetail()
      this.getDocuments()
    },
    methods: {
      getDocuments() {
        const userToken = this.$cookies.get("userToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  }
      const item = JSON.parse(localStorage.getItem('TransferDetail'))
        axios
          .get(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}transfer/${item.transfer_id}`,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              const itemsJoin = []
              response.data.data.map(elem => {
                itemsJoin.push(elem)
              })
              this.documents = itemsJoin
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Get Documents Failed",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
          .catch(e => {
            console.log(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Documents Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      },
    getDetail() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      const item = JSON.parse(localStorage.getItem('TransferDetail'))
      this.modalDetailShow = true
      this.transferNumber = item.transfer_number
      this.transferSender = `${item.sender.branch_name}  (${item.sender.branch_code})`
      this.transferReceiver = `${item.receiver.branch_name}  (${item.receiver.branch_code})`
      this.transferIsConfirmed = item.transfer_confirmed_by_lab_manager_sender === true && item.transfer_confirmed_by_lab_manager_receiver === true ? true : false
      this.transferIsReceived = item.transfer_received_by_receiver === true ? true : false
      this.transferNotes = item.transfer_notes
      this.transferType = item.transfer_type
      const id = item.transfer_id
    const obj1 = {
      title: 'Created',
      content: `Transfer Has been Created By ${item.creator.user_fullname} at ${this.dateSimple(item.transfer_created_time, 'YYYY-MM-DD HH:mm:ss')}`,
      stepCssClass: "has-step-green",
      boxCssClass: "has-color-red",
    }
    const obj2 = {
      title: 'Lab Manager Requester',
      content: item.transfer_confirmed_by_lab_manager_receiver === true ? `Transfer Has been Accepted by ${item.lab_manager_receiver.user_fullname} at ${this.dateSimple(item.transfer_confirmed_by_lab_manager_receiver_time)}` : `Transfer Has not been Accepted By Lab Manager Requester`,
      stepCssClass: item.transfer_confirmed_by_lab_manager_receiver === true ? "has-step-green" : "has-step-red",
    }
    const obj3 = {
      title: 'Regional Manager Requester',
      content: item.transfer_confirmed_by_regional_manager_sender === true ? `Transfer Has been Accepted by ${item.regional_manager.user_fullname} at ${this.dateSimple(item.transfer_confirmed_by_regional_manager_sender_time)}` : `Transfer Has not been Accepted By Regional Manager`,
      stepCssClass: item.transfer_confirmed_by_regional_manager_sender === true ? "has-step-green" : "has-step-red",
    }
    const obj4 = {
      title: 'Division Head',
      content: item.transfer_confirmed_by_division_head_sender === true ? `Transfer Has been Accepted by ${item.division_head.user_fullname} at ${this.dateSimple(item.transfer_confirmed_by_division_head_sender_time)}` : `Transfer Has not been Accepted By Division Head`,
      stepCssClass: item.transfer_confirmed_by_division_head_sender === true ? "has-step-green" : "has-step-red",
    }
    const obj5 = {
      title: 'FA Coal Manager',
      content: item.transfer_confirmed_by_fa_coal_manager_sender === true ? `Transfer Has been Accepted by ${item.fa_coal_manager.user_fullname} at ${this.dateSimple(item.transfer_confirmed_by_fa_coal_manager_sender_time)}` : `Transfer Has not been Accepted By FA Coal Manager`,
      stepCssClass: item.transfer_confirmed_by_fa_coal_manager_sender === true ? "has-step-green" : "has-step-red",
    }
    const obj6 = {
      title: 'Lab Manager Sender',
      content: item.transfer_confirmed_by_lab_manager_sender === true ? `Transfer Has been Accepted by ${item.lab_manager_sender.user_fullname} at ${this.dateSimple(item.transfer_confirmed_by_lab_manager_sender_time)}` : `Transfer Has not been Accepted By Lab Manager Receiver`,
      stepCssClass: item.transfer_confirmed_by_lab_manager_sender === true ? "has-step-green" : "has-step-red",
    }
    const obj7 = {
      title: 'Warehouse Sender',
      content: item.transfer_sent_by_sender === true ? `Transfer Has been Sent By ${item.warehouse_sender.user_fullname} at ${this.dateSimple(item.transfer_sent_by_sender_date)}` : `Transfer Has not been Sent by Warehouse`,
      stepCssClass: item.transfer_sent_by_sender === true ? "has-step-green" : "has-step-red",
    }
    const obj8 = {
      title: 'Warehouse Receiver',
      content: item.transfer_received_by_receiver === true ? `Transfer Has been Received By ${item.warehouse_receiver.user_fullname} at ${this.dateSimple(item.transfer_received_by_receiver_date)}` : `Transfer Has not been Received by Warehouse`,
      stepCssClass: item.transfer_received_by_receiver === true ? "has-step-green" : "has-step-red",
    }
    this.statusItems = []
    this.statusItems.push(obj1)
    this.statusItems.push(obj2)
    this.statusItems.push(obj3)
    this.statusItems.push(obj4)
    this.statusItems.push(obj5)
    this.statusItems.push(obj6)
    this.statusItems.push(obj7)
    this.statusItems.push(obj8)
      axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_TRANSFER_DETAILS}${id}/with_send`, { headers })
      .then(response => {
        if (response.data.success === true) {
          const temps = []
          response.data.data.map(elem => {
            let temp = {}
            if (this.transferType === 'Non Asset') {
            temp = {
              id: elem.transfer_detail_id,
              item: elem.item,
            qty: elem.transfer_detail_item_qty_requested,
            accepted: elem.transfer_detail_item_qty_requested,
            shelves: elem.shelves,
            shelf: "",
            }
          } else {
            temp = {
            id: elem.transfer_detail_id,
            item: elem.item,
            qty: elem.transfer_detail_item_qty_requested,
            accepted: elem.transfer_detail_item_qty_requested,
            asset: elem.asset,
            }
          }
            temps.push(temp)
          })
          this.items = temps
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
    print() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      const id = this.transferId
      axios
      .get(`${process.env.VUE_APP_API_DOWNLOAD_purchase_order}${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
          console.log(response.data.data)
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Download Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
    dateSimple(value, format = 'YYYY-MM-DD') {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else { dateRet = null }
        return dateRet
    },
    previous() {
          this.$router.push({ name: 'apps-transfers-list' })
    },
      numberFormat(value) {
        return parseFloat(value).toFixed(2)
      },
    submitConfirmTransfer() {
      this.isSubmitDisabled = true
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      const item = JSON.parse(localStorage.getItem('TransferDetail'))
      const id = item.transfer_id
      const body = {
        transfer_items: JSON.stringify(this.items),
        time: moment(),
      }
      let shelfFilled = true
      let qtyField = true
      this.items.map(o => {
        if (o.shelf === "") {
            shelfFilled = false
        }
        if (o.accepted === '0') {
            qtyField = false
        }
        if (o.accepted === '') {
            qtyField = false
        }
        if (o.accepted === 0) {
            qtyField = false
        }
      })
      if (shelfFilled === true && item.transfer_type !== 'Asset') {
        if (qtyField === true) {
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_TRANSFER_BY_WAREHOUSE_SENDER}/${id}`
      axios
        .post(url, body, { headers })
        .then(response => {
      this.isSubmitDisabled = false
          if (response.data.success === true) {
            this.chosens = []
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Transfer Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.replace({ name: 'apps-transfers-list' })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Confirm Transfer Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
      this.isSubmitDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Confirm Transfer Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      this.isSubmitDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Please fill all qty',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    }
    } else if (item.transfer_type === 'Asset') {
        if (qtyField === true) {
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_TRANSFER_BY_WAREHOUSE_SENDER}/${id}`
      axios
        .post(url, body, { headers })
        .then(response => {
      this.isSubmitDisabled = false
          if (response.data.success === true) {
            this.chosens = []
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Transfer Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.replace({ name: 'apps-transfers-list' })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Confirm Transfer Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
      this.isSubmitDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Confirm Transfer Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      this.isSubmitDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Please fill all qty',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    }
    } else {
      this.isSubmitDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
                title: `Please fill all shelves`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    }
    },
      downloadAttachment(document) {
        const userToken = this.$cookies.get("userToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  }
        const FileName = document.document_file_name
        const ObjectName = `${document.document_number}${FileName}`
        const body = {
          ObjectName,
          FileName,
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_ASSET}`,
            body,
            { headers },
          )
          .then(response => {
            if (response.data.Status === 1) {
              window.open(response.data.Payload, "_blank")
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Download Attachment success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
      },
    },
  }
  </script>